import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Datepicker, {
      modelValue: _ctx.dateRange,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
        _ctx.changeDate
      ],
      range: "",
      multiCalendars: _ctx.range,
      placeholder: _ctx.placeholder,
      textInput: "",
      enableTimePicker: false,
      selectText: _ctx.selectText,
      cancelText: _ctx.cancelText,
      monthChangeOnScroll: false,
      maxDate: new Date(),
      position: "left",
      locale: "es",
      dayNames: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
      class: _normalizeClass(_ctx.classStyle)
    }, null, 8, ["modelValue", "multiCalendars", "placeholder", "onUpdate:modelValue", "selectText", "cancelText", "maxDate", "class"])
  ]))
}