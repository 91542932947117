
    import { defineComponent, ref, onMounted, watch } from "vue";
    import DateRange from "@/components/daterange/DateRange.vue";
    import SolanaServices from "@/core/services/SolanaServices";
    import Vehicles from "@/views/vehicles/VehiclesView.vue";
    import { useRoute } from "vue-router";
    import router from "../../router";
    import axios from "axios";

    interface dashboardData {
        agencia_info: {
            ciudad: string,
            colonia: string,
            cp: string,
            domicilio: string,
            estado:string,
            nombre:string
        },
        avaluos: {
            total: number,
            comprados: number,
            cancelados: number,
            en_documentacion: number,
            en_negociacion: number
        },
        citas_avaluos: {
            total: number,
            pendiente: number,
            cancelada: number,
            atendida: number,
            efectivas: number
        },
        citas_venta: {
            total: number,
            pendiente: number,
            cancelada: number,
            atendida: number
        },
        leads_y_contacto: {
            wasap_clicks: number,
            phone_clicks: number,
            leads: number
        },
        vehiculos_de_agencia: {
            activos: number,
            vendidos: number,
            apartados: number,
            vistos: number,
            proximamente: number
        },
        marcas_populares: any[],
        modelos_populares: any[],
        vehiculos_vendidos: number,
        avaluos_en_agencia: number,
        citas_agendadas_en_sitio: number
    }

    export default defineComponent({
        name: "DashboardDetail",
        components: {
            DateRange,
            Vehicles
        },
        setup() {

            const route = useRoute();
            const tipoRol = ref<string>("");
            const placesHolderDates = ref<string>("");
            const dashboardData = ref<dashboardData>({
                agencia_info: {
                    ciudad: "",
                    colonia: "",
                    cp: "",
                    domicilio: "",
                    estado:"",
                    nombre:""
                },
                avaluos: {
                    total: 0,
                    comprados: 0,
                    cancelados: 0,
                    en_documentacion: 0,
                    en_negociacion: 0
                },
                citas_avaluos: {
                    total: 0,
                    pendiente: 0,
                    cancelada: 0,
                    atendida: 0,
                    efectivas: 0
                },
                citas_venta: {
                    total: 0,
                    pendiente: 0,
                    cancelada: 0,
                    atendida: 0
                },
                leads_y_contacto: {
                    wasap_clicks: 0,
                    phone_clicks: 0,
                    leads: 0
                },
                vehiculos_de_agencia: {
                    activos: 0,
                    vendidos: 0,
                    apartados: 0,
                    vistos: 0,
                    proximamente: 0
                },
                marcas_populares: [
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                ],
                modelos_populares: [
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                ],
                vehiculos_vendidos: 0,
                avaluos_en_agencia: 0,
                citas_agendadas_en_sitio: 0,
            });
            const idAgencie = ref<any>();
            const firstDate = ref<string>("");
            const secondDate = ref<string>("");
            const returnGo = () => {
                router.go(-1);
            };

            const getTipoRol = async () => {
                const {data} = await SolanaServices.getTipoRol();
                tipoRol.value = data.data[0].type;
            }

            const getDashboard = async (id) => {
                const {data} = await SolanaServices.getDashboard(id);
                dashboardData.value = data.data;
            } 

            watch(()=> route.params.id, async(val)=>{
                idAgencie.value = val;
                await getDashboard(val);
            });

            /**
             * @method getDates
             * @params none
             * @return dates from calendar
             */
             const getDates = async (evt) => {
                if(evt.firstDate != undefined){
                    firstDate.value = evt.firstDate;
                }
                if(evt.secondDate != undefined){
                    secondDate.value = evt.secondDate;
                }
                if(firstDate.value != '' && secondDate.value != ''){
                    const {data} = await SolanaServices.getDashboard(idAgencie.value, firstDate.value, secondDate.value);
                    console.log(data);
                    dashboardData.value = data.data;
                }
            };


        

            onMounted(async() => {
                var date = new Date();
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                //FirsDate
                let dayFormat = "";
                let mesFormat = "";
                let month = firstDay.getMonth() + 1;
                let day = firstDay.getDate();
                if(day.toString().length == 1){
                    dayFormat = '0'+day;
                }else dayFormat = day.toString();
                if(month.toString().length == 1){
                    mesFormat = '0'+month;
                }else mesFormat = month.toString();
                //LastDate
                let ldayFormat = "";
                let lmesFormat = "";
                let lmonth = lastDay.getMonth() + 1;
                let lday = lastDay.getDate();
                if(lday.toString().length == 1){
                    ldayFormat = '0'+lday;
                }else ldayFormat = lday.toString();
                if(lmonth.toString().length == 1){
                    lmesFormat = '0'+lmonth;
                }else lmesFormat = lmonth.toString();
                placesHolderDates.value = dayFormat + '/' + mesFormat + '/' + firstDay.getFullYear() + ' - ' + ldayFormat + '/' + lmesFormat + '/' + lastDay.getFullYear();
                let fechaFrom = firstDay.getFullYear()+'-'+mesFormat+'-'+dayFormat;
                let fechaTo = lastDay.getFullYear()+'-'+lmesFormat+'-'+ldayFormat;
                idAgencie.value = route.params.id;
                await getTipoRol();
                const {data} = await SolanaServices.getDashboard(idAgencie.value, fechaFrom, fechaTo);
                dashboardData.value = data.data;
            });

            return {
                dashboardData,
                idAgencie,
                returnGo,
                getTipoRol,
                tipoRol,
                firstDate,
                secondDate,
                getDates,
                placesHolderDates
            };
        }
    });
