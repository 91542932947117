
    import { defineComponent, ref } from "vue";
    
    export default defineComponent({
        name: "DateRabge",
        emit: ["getDates"],
        props: {
            range: {
                type: Boolean,
                required: false,
                default: false,
            },
            placeholder: {
                type: String,
                required: false,
                default: "Rango de fechas",
            },
            selectText: {
                type: String,
                required: false,
                default: "Select",
            },
            cancelText: {
                type: String,
                required: false,
                default: "Cancel",
            },
            classStyle: {
                type: String,
                required: false,
                default: "dashboard",
            }
        },
        setup(props, { emit }) {
            const dateRange = ref(new Date());
            const changeDate = (modelData) => {
                if(modelData != null){
                    dateRange.value = modelData;
                    let firstDate = getDateFormat(dateRange.value[0]);
                    let secondDate = getDateFormat(dateRange.value[1]);
                    let data = {
                        firstDate: firstDate,
                        secondDate: secondDate,
                    };
                    emit("getDates", data);
                }else{
                    let data = {
                        firstDate: "",
                        secondDate: "",
                    };
                    emit("getDates", data);
                }
            }

            const getDateFormat = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${year}-${month}-${day}`;
            }

            return {
                changeDate,
                dateRange,
            };
        },
    });
